<template>
  <div class="form">
    <b-form @submit.prevent="onSubmit(mode)">
      <p class="form__title">
        Fiche utilisateur
      </p>
      <b-container fluid>
        <b-row class="form__row">
          <b-col :md="4">
            <b-form-group id="input-group-1" label="Nom" label-for="input-1">
              <b-form-input id="input-1" ref="form.nom" v-model="form.nom" placeholder="Entrez votre nom" required />
            </b-form-group>
            <b-form-group id="input-group-2" label="Prenom" label-for="input-2">
              <b-form-input id="input-2" ref="form.prenom" v-model="form.prenom" placeholder="Entrez votre prenom"
                            required />
            </b-form-group>
            <b-form-group id="input-group-identifiant" label="identifiant" label-for="input-identifiant">
              <b-form-input id="input-identifiant" ref="form.identifiant" v-model="form.identifiant" required />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row class="form__row">
          <b-col :md="4">
            <b-form-group id="input-group-4" label="Departement" label-for="input-4">
              <b-form-input id="input-4" ref="form.departement" v-model="form.departement"
                            placeholder="Entrez votre departement" required />
            </b-form-group>
            <b-form-group id="input-group-5" label="role" label-for="input-5">
              <b-form-select v-if="mode === 'modifier'" v-model="form.roles[0]" :options="roleList" />
              <b-form-select v-else v-model="roles" :options="roleList" />
            </b-form-group>
            <b-form-group id="input-group-5" label="Enregistrement des appels" label-for="input-5">
              <b-form-select v-if="mode === 'modifier'" v-model="form.profile.callRecording"
                             :options="callRecording" />
              <b-form-select v-else v-model="form.callRecording" :options="callRecording" />
            </b-form-group>
            <b-form-group id="input-group-6" label="Telephone" label-for="input-6">
              <b-form-input id="input-6" ref="form.telephone" v-model="form.telephone" type="number"
                            placeholder="Entrez votre telephone" required />
            </b-form-group>
            <b-form-group id="input-group-7" label="Email address:" label-for="input-7">
              <b-form-input id="input-7" ref="form.email" v-model="form.email" type="email" placeholder="Enter email"
                            required />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row class="form__row">
          <b-col :md="4" class="d-flex">
            <b-form-group id="input-group-8" label="Status" label-for="input-8">
              <b-form-checkbox id="status" ref="form.status" v-model="form.status" name="check-button" switch />
            </b-form-group>
            <b-form-group v-if="mode === 'modifier' && form.roles[0] === 'LeRefuge.Ecoutant'" id="input-group-9"
                          label="Backup" label-for="input-9">
              <b-form-checkbox id="backup" ref="form.isBackup" v-model="form.profile.isBackup" name="check-button"
                               switch />
            </b-form-group>
            <b-form-group v-show="roles === 'LeRefuge.Ecoutant'" id="input-group-9" label="Backup" label-for="input-9">
              <b-form-checkbox v-model="form.isBackup" name="check-button" switch />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row class="form__row m-2">
          <b-button v-if="mode !== 'modifier'" type="submit" variant="primary">
            Ajouter
          </b-button>
          <b-button v-else type="submit" variant="primary">
            Modifier
          </b-button>
        </b-row>
      </b-container>
    </b-form>
  </div>
</template>

<script>
import {
  BContainer,
  BCol,
  BRow,
  BForm,
  BFormGroup,
  BFormInput,
  BFormCheckbox,
  BButton,
  BFormSelect,
} from 'bootstrap-vue'
import store from '@/store'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { ref } from '@vue/composition-api'

export default {
  components: {
    BContainer,
    BCol,
    BRow,
    BForm,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BButton,
    BFormSelect,
  },
  props: {
    userData: {
      default: () => ({}),
      type: Object,
      required: false,
    },
    email: {
      default: '',
      type: String,
      required: false,
    },
    phone: {
      default: '',
      type: String,
      required: false,
    },
    mode: {
      type: String,
      required: true,
    },
  },
  setup(userData) {
    const form = {
      ...userData.userData,
    }
    const roles = ref('')
    const mail = userData.email
    const phoneNumber = userData.phone
    const toast = useToast()

    const roleList = [
      { value: 'LeRefuge.Administrateur', text: 'Administrateur' },
      { value: 'LeRefuge.Coordinateur', text: 'Coordinateur' },
      { value: 'LeRefuge.Ecoutant', text: 'Ecoutant' },
    ]

    const callRecording = [
      { value: true, text: 'Oui' },
      { value: false, text: 'Non' },
    ]

    const displaySuccessToast = (title, icon, mode) => {
      toast({
        component: ToastificationContent,
        props: {
          title,
          position: 'bottom-right',
          icon,
          variant: mode,
        },
      })
    }

    function onSubmit(mode) {
      const profile = {
        department: form.departement,
        isActive: form.status,
        isBackup: form.isBackup,
        callRecording: form.callRecording,
      }
      const userObj = {
        givenName: form.prenom,
        familyName: form.nom.toUpperCase(),
        profile,
        roles: [roles.value],
        email: form.email,
        emailConfirmed: false,
        phoneNumberConfirmed: false,
        phoneNumber: form.telephone,
        userName: form.identifiant,
      }
      if (mode === 'ajouter') {
        store.dispatch('users/addUser', userObj)
          .then(() => {
            displaySuccessToast('Utilisateur ajouté', 'CheckIcon', 'success')
            this.$emit('submit', true)
          })
          .catch((error => {
            displaySuccessToast(error.toString(), 'XCircleIcon', 'danger')
          }))
      } else {
        const NewUserObj = {
          ...userObj,
          roles: form.roles,
          profile: {
            department: form.departement,
            isActive: form.status,
            isBackup: form.profile.isBackup,
            callRecording: form.profile.callRecording,
          },
          id: form.id,
        }
        if (NewUserObj.roles[0] !== 'LeRefuge.Ecoutant') {
          NewUserObj.profile.isBackup = false
        }
        if (mail === form.email) {
          NewUserObj.emailConfirmed = true
        }
        if (phoneNumber === form.telephone) {
          NewUserObj.phoneNumberConfirmed = true
        }
        store.dispatch('users/updateUser', NewUserObj)
          .then(() => {
            displaySuccessToast('Utilisateur modifié', 'CheckIcon', 'success')
            store.dispatch('users/fetchUsers')
          })
          .catch((error => {
            displaySuccessToast(error.toString(), 'XCircleIcon', 'danger')
          }))
        this.$emit('submit', true)
      }
    }

    return {
      mail,
      callRecording,
      phoneNumber,
      toast,
      roles,
      roleList,
      onSubmit,
      displaySuccessToast,
      form,
    }
  },
}
</script>

<style lang="scss" scoped>
.form {
  &__title {
    font-size: 24px;
    display: flex;
    justify-content: center;
    margin: 2rem 0;
    color: #16B5EA;
    font-weight: 700;
  }

  &__row {
    justify-content: center;
  }
}
</style>
